.contract-body {
    display: flex;
    flex-direction: row;
    margin-top: 10vh;
}

.contract-area{
    resize: none;
    width: 40vw;
    height: 60vh;
    padding: 15px;
    font-size: 15px;
    border: 1px solid #000;
    border-radius: 10px;
    font-family: "Fira Code";
    box-shadow: none;
    outline: none;
}
.contract-area::placeholder {
    font-size: 15px;
}

.explain-button{
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background-color: white;
    padding: 25px;   
}

.arrow{
    rotate: 180deg;
}