.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.header{
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  background-color: #F6F1F1;
  
}

.title {
  color: #122A87;
  margin-left: 20px;
}

.nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  color: #635D8F;
  background-color: #ffffff;
  margin-right: 20px;
  height: 69%;
  border-radius: 20px;
}

.tab{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: space-between;
  font-size: calc(5px + 2vmin);
  cursor: pointer;
  padding: 20px;
  border-radius: 20px;
}

.body{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
}