.form{
    display: flex;
    flex-direction: row; 
    justify-content: center;
    margin-top: 10vh;
    width: 100vw;
}

.search-box{
    padding: 15px 70px 15px 15px;
    width: 700px;
    height: fit-content;
    background-color: #F6F1F1;
    border-radius: 20px;
    border: none;
    font-size: 20px;
    box-shadow: none;
    outline: none;
    transform: translateX(20px);
}
::placeholder{
    font-size: 20px;
}

.search-button{
    position: relative;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background-color: #F6F1F1;
    height: fit-content;
    padding: 0px 0px;
    transform: translate(-35px, 8px);
}

.arrow{
    rotate: 180deg;
}

.dropdown{
    display: flex;
    flex-direction: row; 
    justify-content: center;
    border-radius: 10px;
    width: 100vw;
}

.dropdown-items{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #000;
    background-color: #FFFFFF;
    width: 785px;
}

.item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
}
.item:hover{
    background-color: #F6F1F1;
}

.item-type{
    padding: 5px;
    border-radius: 10px;
}


.item-name{
    align-items: left;
}

.item-address{
    color: #a9a9a9;
}

.search-result{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    font-size: 20px;
    width: 100vw;
}